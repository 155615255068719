@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

@layer components {
  .layout-container {
    @apply container mx-auto px-4 py-4 max-w-6xl;
  }

  .partners-text {
    @apply font-bold text-gray-500 text-sm;
  }

  .base-button {
    @apply w-full sm:w-fit inline-flex justify-center items-center py-2 px-4 text-sm font-medium rounded-md gap-2;
  }

  .primary-button {
    @apply base-button border border-transparent shadow-sm  text-white bg-primary hover:bg-primary-dark focus:ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:bg-gray-300;
  }

  .secondary-button {
    @apply bg-white rounded-md p-2 inline-flex items-center justify-center hover:bg-gray-100 text-primary;
  }

  .form-field {
    @apply mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md px-3 py-2 border focus:ring-primary focus:border-primary focus:border-2 focus:outline-0;
  }

  .form-field-error {
    @apply border-red-300 border-2;
  }

  .h3-title {
    @apply font-bold sm:text-lg;
  }
  .h4-title {
    @apply font-semibold;
  }
}
